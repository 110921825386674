//const SERVER_NAME = 'http://localhost:3333';
const SERVER_NAME = 'https://server.cursodaniellevelasco.com.br';

const SERVER_HOSTNAME = SERVER_NAME;
const SERVER_TOKEN_EXPIRETIME = 3600;
const SERVER_TOKEN_ISSUER = 'coolIssuer';
const SERVER_TOKEN_SECRET = 'r3w4bsrcg46m8uen4av4cysg';

const SERVER = {
    hostname: SERVER_HOSTNAME,
    token: {
        expireTime: SERVER_TOKEN_EXPIRETIME,
        issuer: SERVER_TOKEN_ISSUER,
        secret: SERVER_TOKEN_SECRET
    }
};

const config = {
    server: SERVER
};

export default config;