import { createGlobalStyle } from "styled-components";

import cursor1 from "../img/cursor_1.png";
import cursor2 from "../img/cursor_2.png";
import cursor3 from "../img/cursor_3.png";
import cursor4 from "../img/cursor_4.png";
import cursor5 from "../img/cursor_5.png";

const GlobalStyles = createGlobalStyle`

.shidden{
  overflow: hidden !important;
  margin: 0px !important;
}

.mt-60{
  margin-top: 60px !important;
}

body{
  margin-top: 133px;
}

.none{
  display: none;
}

.opacidade1{
  opacity: 1 !important;
}
/*
.ponteiro_0{
  cursor: url('${cursor1}'), auto !important;
}

.ponteiro_1{
  cursor: url('${cursor1}'), auto !important;
}
.ponteiro_2{
  cursor: url('${cursor2}'), auto;
}
.ponteiro_3{
  cursor: url('${cursor3}'), auto;
}
.ponteiro_4{
  cursor: url('${cursor4}'), auto;
}
.ponteiro_5{
  cursor: url('${cursor5}'), auto;
}*/

.alert-info{
  cursor: pointer;
  background-color: #007bc6;
  color: #fff;
}

.btn-blue{
  background-color: #007bc6;
  border-radius: 0px;
}

.full{
  width: 100%;
}

.flex-c{
  flex-direction: column;
}

legend{
  color: #333;
  font-weight: bold;
  font-size: 24px;
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 2px solid #ccc;
}

.flex100{
  position: relative;
  display: flex;
  width: 100%;
}

.fdark{
  color: #000;
  display: flex;
  flex-basis: auto;
  
  &.dele{
    padding-right: 30px !important;
  }

  .form-control{
    width: 100%;
  }
}

.alinhar-icone-texto{
  display: flex;
  align-items: center;
  margin: 15px;

  svg{
    margin-right: 10px;
  }
}

.hideinput{
  display: none;
}

.carregando{
  width: 16px;
  height: 16px;
}

.conteudoPDF{
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 999;
  background-color: #fff;

  &.false{
    display: none !important;
  }
}

.competencia_0{
  
  .AreaHighlight{
    background-color: rgba(0,0,0,0.3) !important;
  }
  .AreaHighlight__part{
    background-color: rgba(0,0,0,0.3) !important;
  }
}

.competencia_1{
  
  .AreaHighlight{
    background-color: rgba(177,0,168,0.3) !important;
  }
  .AreaHighlight__part{
    background-color: rgba(177,0,168,0.3) !important;
  }
}

.competencia_2{
  
  .AreaHighlight{
    background-color: rgba(255,0,0,0.3) !important;
  }
  .AreaHighlight__part{
    background-color: rgba(255,0,0,0.3) !important;
  }
}

.competencia_3{
  
  .AreaHighlight{
    background-color: rgba(29,29,245,0.3) !important;
  }
  .AreaHighlight__part{
    background-color: rgba(29,29,245,0.3) !important;
  }
}

.competencia_4{
  
  .AreaHighlight{
    background-color: rgba(10,208,0,0.3) !important;
  }
  .AreaHighlight__part{
    background-color: rgba(10,208,0,0.3) !important;
  }
}

.competencia_5{
  
  .AreaHighlight{
    background-color: rgba(208,201,0,0.3) !important;
  }
  .AreaHighlight__part{
    background-color: rgba(208,201,0,0.3) !important;
  }
}

.competencia_undefined{
  
  .AreaHighlight{
    background-color: rgba(0,0,0,0.3) !important;
  }
  .AreaHighlight__part{
    background-color: rgba(0,0,0,0.3) !important;
  }
}

.sidebar__highlights{
  list-style: none;
  padding: 0px;

  li{
    margin-bottom: 10px;
    border: 1px solid #000;
    padding: 5px;
    font-size: 12px;

    img{
      height: auto;
      max-width: 100%;
    }
  }
}

.ir-direita{
  position: relative;
  float: right;
}

.clicavel{
  cursor: pointer;
}

.grandao{
  height: 100vh !important;
  background-color: rgba(0,0,0,0.5) !important;
  width: 100% !important;
  display: flex !important;
  vertical-align: middle !important;
  align-items: center !important;
  margin: 0px !important;
  max-width: 100% !important;
  padding: 0 50px !important;
}

.botaoNota{
  background-color: rgba(29,29,245, 1);
  color: #fff;
  border: 0px;
  border-radius: 40px;
  width: 40px !important;
  height: 40px !important;
  cursor: pointer;
  display: block !important;
  padding: 0px !important;
  margin: 0 auto;

  &.ativo{
    background-color: rgba(255,0,0,1);
  }
}

.sidebar__highlight{

 
  &.competencia_0{
    background-color: rgba(0,0,0,0.3);
    color: #fff;
  }


  &.competencia_1{
    background-color: rgba(177,0,168,0.3);
    color: #000;
  }

  &.competencia_2{
    background-color: rgba(255,0,0,0.3);
    color: #000;
  }

  &.competencia_3{
    background-color: rgba(29,29,245,0.3);
    color: #000;
  }

  &.competencia_4{
    background-color: rgba(10,208,0,0.3);
    color: #000;
  }

  &.competencia_5{
    background-color: rgba(208,201,0,0.3);
    color: #000;
  }
}

.visible{
  display: block;
}

.marcado{
  background-color: #cf6969;
  color: #fff;
}

.fixar{
  position: fixed;
  z-index: 9999999999999999999999999999999999;
  background-color: #fff;
  bottom: 0;
  left: 0;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.caixaAviso{
    display: block;
    padding: 10px 10px;
    margin: 5px 0px;
    font-size: 13px;
  }


  .c100p{
    width: 100%;
  }

  .botao{
    display: inline-block;
    padding: 10px 30px;
    margin: 5px;
    font-size: 13px;
    cursor: pointer;

    &.black{
      background-color: rgba(177,0,168,0.3);
      color: #000;
    }

    &.red{
      background-color: rgba(255,0,0,0.3);
      color: #000;
    }

    &.blue{
      background-color: rgba(29,29,245,0.3);
      color: #000;
    }

    &.green{
      background-color: rgba(10,208,0,0.3);
      color: #000;
    }

    &.yellow{
      background-color: rgba(208,201,0,0.3);
      color: #000;
    }
  }

  .sidebar{
    overflow-y: scroll;
    max-height: 70vh;
  }

  .modal-dialog.full{
    width: 100% !important;
    height: 100vh !important;
    margin: 0px;
    border-radius: 0px !important;
    display: block;
    max-width: 100%;

    .modal-content{
      width: 100% !important;
      height: 100vh;
      border-radius: 0px !important;
      display: block;
    }

    .modal-body{
      width: 100% !important;
      display: block;
      padding: 0px 1rem;
    }

    .modal-title{
      width: 100%;
      display: block;
    }
  }

  .relativo{
    position: relative;
  }

  .ql-hidden{
    display: none !important;
  }

  .ql-editor{
    background-color: #fefefe;
    border:1px solid #3e3e3e;
    padding: 15px;
    border-radius: 5px;
    margin: 15px 0px;
    width: 100%;
  }
 
 

  // caixa de correção feita na mão
  .caixa-correcao{
    position: absolute;
    z-index: 3;
    background-color: rgba(0,0,0,1);
    width: 260px !important;
    max-width: 500px !important;
    display: block;
    padding: 15px;

    textarea{
      font-size: 11px;
      margin: 3px;
      width: 220px;
    }

    select{
      position: relative;
      font-size: 11px;
      margin: 3px;
      width: 220px;
    }

    button{
      margin: 3px;
    }
  }

  .th-grande{
    min-width: 150px;
  }

  .th-xl{
    min-width: 350px;
  }

  @media (max-width: 991px){
    .navbar-nav{
      .nav-link{
        border-top: 1px solid #eee;
        font-size: 18px !important;
      }
    }

    body{
      padding-top: 50px;
    }
  }

  .mhover{
    cursor: pointer;
  }

  .table-responsive{height:600px;overflow:scroll;} 
  .table-responsive thead tr:nth-child(1) th{background: white; position: sticky;top: -10px; z-index: 10;}
  @media (max-width: 991px){
    .table-responsive{height:400px;overflow:scroll;} 
  }
`;

export default GlobalStyles;
