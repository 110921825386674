import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import api from "../../services/api";
import * as S from "./styled";

import { toast } from "react-toastify";

import { useAuth } from "../../context/auth";

import Logo from "../../assets/logo.png";

function Login() {
  const [login, setLogin] = useState("");
  const [pass, setPass] = useState("");
  const [aviso, setAviso] = useState(false);
  const [recuperar, setRecuperar] = useState(false);
  const [enviado, setEnviado] = useState();

  const [novoEmail, setNovoEmail] = useState("");

  
  const history = useHistory();

  const { user, setUser } = useAuth();

  async function loginHandler(e) {
    e.preventDefault();

    const dataLogin = {
      user: login,
      password: pass,
    };

    try {
      let response = await api.post("/login", dataLogin);

      setUser({
        nome: response.data.user.name,
        nivel: response.data.user.type,
        id: response.data.user.id,
        turma: response.data.user.turma_id,
      });

      if (response.data.message === "Auth Successful") {
        toast(
          "Seja bem vindo(a)! Você esta sendo redirecionado para a página inicial"
        );
        localStorage.setItem("token", response.data.token);

        setTimeout(() => {
          history.push("/logado");
        }, 1 * 1000);
      }
    } catch (err) {
      toast.error("Usuário não encontrado.");
      localStorage.removeItem("token");
    }
  }

  async function novaSenha(e) {
    e.preventDefault();

    const dataNovaSenha = {
      email: novoEmail,
    };

    try {
      let response = await api.post("/recuperarSenha", dataNovaSenha);

      if (response.status === 204) {
        toast.error("E-mail não encontrado.");
        setAviso("E-mail não encontrado.");
      }

      if (response.status === 200) {
        toast.success(
          "Sua nova senha foi enviado para o email cadastrado. Você já pode tentar fazer o login com sua nova senha."
        );
        setEnviado(
          "Sua nova senha foi enviado para o email cadastrado. Você já pode tentar fazer o login com sua nova senha."
        );
        setAviso(false);
      }
    } catch (err) {
      localStorage.removeItem("token");
    } finally {
      return false;
    }
  }

  useEffect(() => {
    if (user.id > 0) {
      history.push("/logado");
    }
  }, []);

  return (
    <S.LoginWrapper>
      <img src={Logo} alt="Curso Danielle Velascos - Login" />
      <S.FromWrapper>
        <h1>Login</h1>
        <form>
          <input
            placeholder="Login: Email/CPF"
            value={login}
            onChange={(e) => setLogin(e.target.value)}
          />
          <input
            placeholder="Senha"
            type="password"
            onChange={(e) => setPass(e.target.value)}
          />
          <button onClick={loginHandler}>LOGAR</button>
        </form>
      </S.FromWrapper>

      {enviado && (
        <p>
          <hr />
          <strong>
            Nova senha enviada com sucesso! Tente fazer o login com sua nova
            senha.
          </strong>
        </p>
      )}

      <>
        <hr />
        {aviso}

        {recuperar ? (
          <p>
            <form>
              <input
                placeholder="Digite seu e-mail ou CPF (sem pontos e traços)"
                value={novoEmail}
                onChange={(e) => setNovoEmail(e.target.value)}
              />
              <button onClick={novaSenha}>Recuperar senha</button>
            </form>
          </p>
        ) : (
          <p>
            Não lembra sua senha?
            <strong onClick={() => setRecuperar(true)}> RECUPERAR SENHA</strong>
            .
          </p>
        )}
      </>
    </S.LoginWrapper>
  );
}

export default Login;
