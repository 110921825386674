import axios from "axios";
import config from "../services/config";

const Deslogar = (erro) => {


  localStorage.removeItem("token");
  window.location.href = "/";
};

function pegarToken() {
  return localStorage.getItem("token");
}

const api = axios.create({
  baseURL: config.server.hostname,
  headers: { Authorization: `Bearer ${pegarToken()}` },
});

// Add a response interceptor
api.interceptors.response.use(
  function (response) {
    if (response.data) {
      if (
        response.data.message === "Não autorizado" ||
        response.data.message === "jwt malformed"
      ) {
        Deslogar();
      }
    }
    return response;
  },
  function (error) {

    if(error.response.status === 404) {
      //console.log('Entrei no 404?');
      Deslogar();
      return false;
    }
    return Promise.reject(error);
  }
);

api.interceptors.request.use(
  async (config) => {
    const token = pegarToken();

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  }
);

export default api;
