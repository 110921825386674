import React from "react";
import ReactDOM from "react-dom";
import { ToastContainer } from "react-toastify";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import "react-toastify/dist/ReactToastify.css";
import { AuthProvider } from "./context/auth";
import { CompetenciaProvider } from "./context/competencia";
import App from "./App";

ReactDOM.render(
  <React.StrictMode>
    <AuthProvider>
      <CompetenciaProvider>
        <ToastContainer position="top-right" closeOnClick pauseOnHover />
        <App />
      </CompetenciaProvider>
    </AuthProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();
