import Menu from '../components/Menu';
import GlobalStyles from "../assets/css/global";

export default function Template(props) {
    return(
        <>
            <GlobalStyles />
            <Menu />
             {props.children} 
        </>
    )
}