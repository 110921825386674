import "bootstrap/dist/css/bootstrap.min.css";
import io from "socket.io-client";
import Routes from "./routes";
import config from './services/config';

const socket = io(config.server.hostname); 
 
function App() {
  return <Routes socket={socket} />;
}

export default App;