import { useState, useEffect } from "react";
import { Form, Container, Row, Col, Card, Button } from "react-bootstrap";
import * as S from "./styled";
import api from "../../services/api";
import { useAuth } from "../../context/auth";
import { toast } from "react-toastify";

import Template from "../../template";

function Main() {
  const user = useAuth();

  const [nome, setNome] = useState("");
  const [email, setEmail] = useState("");
  const [telefone, setTelefone] = useState("");
  const [cpf, setCPF] = useState("");
  const [senha, setSenha] = useState("");
  const [confirmaSenha, setConfirmaSenha] = useState("");
  const [novaSenha, setNovaSenha] = useState("");
  const [totalFeitas, setTotalFeitas] = useState(0);
  const [erro, setErro] = useState("");

  async function atualizarCadastro() {
    let dataUser = {};

    if (senha !== confirmaSenha) {
      setErro("As suas senhas não batem");
      toast.error(
        "A alteração de senha não foi possível pois elas não diferentes."
      );
      return false;
    }

    if (novaSenha !== "") {
      setErro("Você alterou sua senha");
      toast.success("Sua senha foi alterada para: " + novaSenha);
      dataUser = {
        name: nome,
        email: email,
        telefone: telefone,
        password: novaSenha,
      };
    } else {
      dataUser = {
        name: nome,
        email: email,
        telefone: telefone,
        password: "",
      };
    }

    try {
      await api.post("/changePerfil", dataUser);
      setErro("Seus dados foram atualizados com sucesso.");
      setSenha("");
      setConfirmaSenha("");
      setNovaSenha("");
    } catch (error) {
      console.log("OI?");
      //history.push("/");
    }
  }

  useEffect(() => {

    

    async function getPerfil() {
      try {
        let response = await api.get("/meuperfil");

        setNome(response.data.name);
        setEmail(response.data.email);
        setTelefone(response.data.phone);
        setCPF(response.data.cpf);
      } catch (err) {}
    }

    async function getRedacoes() {
      try {
        let response = await api.get(`/redacoes`);

        setTotalFeitas(response.data[0].total);
      } catch (err) {}
    }

    getRedacoes();
    getPerfil();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Template>
      <S.MainWrapper>
        <Container>
          <Row>
            <Col sm={12}>
              <S.MainContent>
                <Row>
                  <Col>
                    <S.H1>Seja bem vindo(a) </S.H1>
                    <S.H2>{user.user.nome}</S.H2>
                  </Col>
                </Row>
                <Row>
                  <Col sm={6}>
                    <Card>
                      <Card.Header>REDAÇÕES</Card.Header>
                      <Card.Body>
                        <Card.Title>Feitas: {totalFeitas}</Card.Title>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col sm={6}>
                    <Card>
                      <Card.Header>EVOLUÇÃO</Card.Header>
                      <Card.Body>
                        <Card.Title>Observação</Card.Title>
                        <Card.Text>
                          Em breve você poderá conferir sua evolução, para isso,
                          faça algumas redações.
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </S.MainContent>

              <S.MainContent>
                <Row>
                  <Col>
                    <S.H1>Alterar dados cadastrais</S.H1>

                    <Form>
                      <Form.Label>Nome do Aluno</Form.Label>
                      <Form.Control
                        value={nome}
                        onChange={(e) => setNome(e.target.value)}
                      />
                      <hr />
                      <Form.Label>E-mail do Aluno</Form.Label>
                      <Form.Control
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      <hr />
                      <Form.Label>CPF do Aluno</Form.Label>
                      <Form.Control value={cpf} disabled />
                      <hr />

                      <Form.Label>Telefone do Aluno</Form.Label>
                      <Form.Control
                        value={telefone}
                        onChange={(e) => setTelefone(e.target.value)}
                      />
                      <hr />
                      <p>
                        Para que seus dados sejam alterados, você precisa
                        confirmar sua senha.
                      </p>

                      <Form.Label>Senha</Form.Label>
                      <Form.Control
                        value={senha}
                        type="password"
                        onChange={(e) => setSenha(e.target.value)}
                      />
                      <hr />

                      <Form.Label>Confirmar Senha</Form.Label>
                      <Form.Control
                        value={confirmaSenha}
                        type="password"
                        onChange={(e) => setConfirmaSenha(e.target.value)}
                      />
                      <hr />

                      <p>
                        Você poderá trocar sua senha preenchendo uma nova
                        abaixo, será necessário confirmar sua senha acima.
                        Cuidado.
                      </p>

                      <Form.Label>Nova Senha</Form.Label>
                      <Form.Control
                        value={novaSenha}
                        type="password"
                        onChange={(e) => setNovaSenha(e.target.value)}
                      />
                      <hr />

                      <Button
                        variant="btn btn-info"
                        onClick={atualizarCadastro}
                      >
                        Atualizar Cadastro
                      </Button>

                      <p>
                        <strong>{erro}</strong>
                      </p>
                    </Form>
                  </Col>
                </Row>
              </S.MainContent>
            </Col>
          </Row>
        </Container>
      </S.MainWrapper>
    </Template>
  );
}

export default Main;
