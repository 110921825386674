import React, { useState, useEffect } from "react";

import api from "../services/api";
export const AuthContext = React.createContext({});

export const AuthProvider = (props) => {
  const [user, setUser] = useState({});

  async function getPerfil() {
    try {
      let response = await api.get("/meuperfil");
     
      setUser({
        nome: response.data.name,
        nivel: response.data.type,
        id: response.data.id,
        turma: response.data.turma_id
      });

    } catch (err) {
    } finally {
      
    }
  }

  useEffect(() => {

    if (localStorage.getItem("token")) {
      if(user.length === undefined){
        getPerfil();
      }
    }
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AuthContext.Provider value={{ user, setUser }}>
      {props.children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => React.useContext(AuthContext);
